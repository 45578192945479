import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home.jsx";
import Section5 from "./components/home/Section5.jsx";
import SAndD from "./components/s&d/SAndD";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import BannerTop from "./components/utils/BannerTop";
import ScrollToTop from "./components/utils/ScrollToTop"

// utils
import Header from "./components/utils/Header";
import Footer from "./components/utils/Footer";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <div className='xs:container mx-auto px-20'> */}
        <Header />
        <BannerTop />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/supply-and-distribution" element={<SAndD />} />
          {/* <Route index element={<LeagueStandings />} /> */}
        </Routes>
        <Footer />
        {/* </div> */}
      </BrowserRouter>
    </>
  );
}

export default App;
