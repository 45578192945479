import React from "react";
import nurse from "../assets/images/Frame.jpg";
import Form from "../utils/Form";

const Section5 = () => {
  return (
    <>
      <div className="grid lg:grid-cols-2">
        <div className="w-full h-full pt-20">
          <img src={nurse} alt="nurse" className="w-full" />
        </div>
        <div className="" id="appointment">
          <Form />
        </div>
      </div>
    </>
  );
};

export default Section5;
