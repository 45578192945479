import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";

const Section6Card = ({ handleContent, secSixTabId }) => {
    const [nav, setNav] = useState(false);

    const handleClick = () => setNav(!nav);

    const [obj, setObj] = useState({})
    useEffect(() => {
        setObj(handleContent(secSixTabId))
    }, [secSixTabId, obj])

    return (
        <div className="bg-white">
            <div className="lg:grid lg:grid-cols-5 lg:gap-4 container mx-auto px-5 md:px-20">
                <div className="lg:col-span-5">
                    <h1 className="pt-20 font-bold text-2xl uppercase">{obj?.head}</h1>
                    <p className="pt-3 ">
                        {obj?.para}
                    </p>
                    <h2 className="font-bold pt-3 ">{obj?.head === "COVID-19" ? "Usual Symptoms" : "Services"}</h2>
                    <ul>
                        <li className="pt-3">{obj?.list?.[0]}</li>
                        <li className="pt-3">{obj?.list?.[1]}</li>
                        <li className="pt-3">{obj?.list?.[2]}</li>
                        <li className="pt-3">{obj?.list?.[3]}</li>
                    </ul>
                    <Link to="/contact" onClick={handleClick}>
                        <div className='text-right'>

                            <button className="my-10 bg-[#2B5EAD] text-white rounded-xl px-6 py-3 text-[16px]">
                                Contact Now
                            </button>
                        </div>
                    </Link>
                </div>
                {/* <div className="col-span-2 mt-20">
                        <img
                            className="w-full"
                            src={obj?.image}
                            alt=""
                        />
                    </div> */}
            </div>
        </div>
    )
}

export default Section6Card