import axios from "axios";
import React, { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";

const Form = () => {
  const [isLoadingRegisteration, setIsLoadingRegisteration] = useState(false);
  const [isShowingMsg, setIsShowingMsg] = useState(false);

  const [user, setUser] = useState({
    service: "",
    gender: "",
    fullName: "",
    email: "",
    age: "",
    dAt: "",
    phoneNumber: "",
    problemDescription: "",
  });
  const handleInputs = (e) => {
    let name, value;
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();
    setIsLoadingRegisteration(true);
    // const { service, gender, fullName, email, age, dAt, phoneNumber, problemDescription } = user;
  };

  useEffect(() => {
    if (isLoadingRegisteration) {
      const payload = {
        data: user,
      };
      axios
        .post(`https://aih-sls-api.vercel.app/api/register`, payload)
        .then((res) => {
          console.log(res.data);
          if (res.status === 201) {
            setIsShowingMsg(true);
          }
          setIsLoadingRegisteration(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingRegisteration(false);
        });
    }
  }, [isLoadingRegisteration]);

  return (
    <>
      <form
        method="POST"
        onSubmit={postData}
        className="contact-form  bg-[#333F63] shadow-md rounded p-5 md:p-10 h-full lg:h-[560px] xl:h-full"
        id="contact-form"
      >
        <div className="mb-10">
          {/* <label className="block  text-white font-bold align-top	text-left text-[18px]">
            NEED EMERGENCY
          </label> */}
          <label
            className="text-white truncate  text-[25px] xl:text-[41px] fill-white align-top	text-left leading-none font-semibold pt-3"
            for="password"
          >
            Book an Appointment
          </label>
        </div>

        {!isShowingMsg ? (
          <>
            <div className="mb-6">
              <select
                value={user.service}
                onChange={handleInputs}
                className="placeholder-black bg-gray-200 pl-[24px] xl:mt-6 h-10 xl:h-14 w-full rounded-lg "
                label="Services"
                name="service"
                id="services"
                required
              >
                <option value="">Services</option>
                <option value="Gastroenterology">Gastroenterology</option>
                <option value="Pulmonology">Pulmonology</option>
                <option value="Orthopedic">Orthopedic</option>
              </select>
            </div>

            <div className="mb-6">
              <input
                className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                type="text"
                name="fullName"
                id="name"
                placeholder="Your Name"
                required
                value={user.name}
                onChange={handleInputs}
              />
            </div>

            <div className="mb-6">
              <input
                className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
                required
                value={user.email}
                onChange={handleInputs}
              />
            </div>
            <div className="mb-6 grid md:grid-cols-2 gap-6 md:gap-3">
              <div>
                <select
                  value={user.gender}
                  onChange={handleInputs}
                  className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 rounded-lg w-full"
                  label="Gender"
                  name="gender"
                  id="gender"
                  required
                >
                  <option value="">Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div>
                <input
                  className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                  type="number"
                  name="age"
                  id="age"
                  placeholder="Age"
                  required
                  value={user.age}
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="mb-6 grid sm:grid-cols-2 gap-6 md:gap-3">
              <div>
                <input
                  className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                  type="datetime-local"
                  name="dAt"
                  id="date"
                  placeholder="mm/dd/yyyy --:-- --"
                  required
                  value={user.dAt}
                  onChange={handleInputs}
                />
              </div>
              <div>
                <input
                  className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                  type="text"
                  name="phoneNumber"
                  id="tel"
                  placeholder="Telephone"
                  required
                  value={user.phoneNumber}
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div>
              <textarea
                className="mb-6 py-3 placeholder-black bg-gray-200 px-5 h-[60px] xl:h-[127px] w-full rounded-lg "
                type="textarea"
                name="problemDescription"
                id="problem"
                placeholder="Problem Description"
                required
                value={user.problemDescription}
                onChange={handleInputs}
              />
            </div>

            <div className="">
              {isLoadingRegisteration ? (
                <div className="ml-20 md:ml-52 lg:ml-32 xl:ml-48">
                  <Oval
                    height={80}
                    width={80}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <input
                  className="mb-6 bg-[#2B5EAD] text-white font-semibold py-[18px] w-full rounded-lg cursor-pointer"
                  type="submit"
                  name="contact"
                  value="Contact Now"
                ></input>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="">
              <h1 className="text-white text-2xl">
                &#9989; Thank you! You appointment has been recorded!
              </h1>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default Form;
