import { React, useState } from "react";
import Section2Card from "../utils/Section2Card";
import img1 from "../assets/images/image 8.jpg";
import Pulmonology from "../assets/images/image 9.jpg";
import Orthopedic from "../assets/images/orthopedic.jpg";

import {
  faCapsules,
  faHeartPulse,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";

const Section2 = ({ data }) => {
  const [departmentData, setDepartmentData] = useState([
    {
      id: 1,
      icon: faHeartPulse,
      departmentName: "Gastroenterology",
      image: img1,
      details:
        "If you have any signs or symptoms of a disease affecting your digestive systems such as constipation, diarrhea, heartburn, abdominal pain, or others, you should consult a gastroenterologist.",
    },

    {
      id: 2,
      departmentName: "Pulmonology",
      icon: faCapsules,
      image: Pulmonology,
      details:
        "We provide consultative, diagnostic, and therapeutic services for patients with lung and breathing problems, including chronic obstructive pulmonary disease, asthma, and lung cancer.",
    },

    {
      id: 3,
      image: Orthopedic,
      departmentName: "Orthopedic",
      icon: faStethoscope,
      details:
        "Our expert team treats a variety of conditions that affect the bones, joints, muscles, ligaments, tendons, and nerves. We treat arthritis, bone tumors, cerebral palsy, dislocations, fractures, osteoporosis, and spine disorders.",
    },
  ]);

  return (
    <>
      <Section2Card cardsDate={departmentData} />
    </>
  );
};

export default Section2;
