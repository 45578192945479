import React from 'react'

const showcaseCard = ({ icon, color, heading, content, button, image }) => {
    return (
        <div className={color ? `text-white bg-[${color}] rounded-xl p-8 h-96 xl:h-[22rem] relative` : `text-white bg-[#0092FF] rounded-xl p-8 h-96 relative`}>
            {/* <i className="fa fa-spinner fa-spin">no spinner but why</i> */}
            <div className='mb-2'>
                {icon}
            </div>
            <h3 className='text-[1.8rem] font-semibold'>{heading}</h3>
            <p className='mt-2'>
                {content}
            </p>
            <div className='text-right'>

                {
                    image ?
                        <img src={image} alt="Time" className='float-right' />
                        :
                        null
                }
            </div>
            {
                button ?
                    <div className='absolute bottom-6 left-8'>
                        <a href="#section7">
                            <button class="inline-flex items-center border-2 py-2 px-4 focus:outline-none hover:bg-[#f9f9f9] hover:text-[#2B5EAD] hover:border-[#2B5EAD] rounded text-base mt-4 md:mt-0 rounded-lg">More Info
                            </button>
                        </a>
                    </div> :
                    null
            }
        </div>
    )
}

export default showcaseCard