import React from "react";
import axios from "axios";

import { useState, useEffect } from "react";
import { Oval } from "react-loader-spinner";

const ContactForm = () => {
  const [isLoadingRegisteration, setIsLoadingRegisteration] = useState(false);
  const [isShowingMsg, setIsShowingMsg] = useState(false);

  const [user, setUser] = useState({
    fullName: "",
    email: "",
    message: "",
  });
  const handleInputs = (e) => {
    let name, value;
    console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();
    setIsLoadingRegisteration(true);
    // const { service, gender, fullName, email, age, dAt, phoneNumber, message } = user;
  };

  useEffect(() => {
    if (isLoadingRegisteration) {
      const payload = {
        data: user,
      };
      axios
        .post(`https://aih-sls-api.vercel.app/api/contact`, payload)
        .then((res) => {
          console.log(res.data);
          if (res.status === 201) {
            setIsShowingMsg(true);
          }
          setIsLoadingRegisteration(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingRegisteration(false);
        });
    }
  }, [isLoadingRegisteration]);
  return (
    <>
      <form
        method="POST"
        onSubmit={postData}
        className="contact-form  bg-[#333F63] shadow-md rounded p-5 md:p-10 h-auto lg:h-[560px] xl:h-full"
        id="contact-form"
      >
        <div className="mb-10">
          <label
            className="text-white truncate  text-[25px] xl:text-[41px] fill-white align-top	text-left leading-none font-semibold pt-3"
            for="password"
          >
            Contact Us
          </label>
        </div>

        {!isShowingMsg ? (
          <>
            <div className="mb-6">
              <input
                className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                type="text"
                name="fullName"
                id="name"
                placeholder="Your Name"
                value={user.name}
                onChange={handleInputs}
                required
              />
            </div>

            <div className="mb-6">
              <input
                className="placeholder-black bg-gray-200 pl-[24px] h-10 xl:h-14 w-full rounded-lg "
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
                value={user.email}
                onChange={handleInputs}
                required
              />
            </div>
            <div>
              <textarea
                className="mb-6 py-3 placeholder-black bg-gray-200 px-5 h-[96px] xl:h-[150px] w-full rounded-lg "
                type="textarea"
                name="message"
                id="message"
                placeholder="Your Message"
                required
                value={user.message}
                onChange={handleInputs}
              />
            </div>

            <div className="">
              {isLoadingRegisteration ? (
                <div className="ml-20 md:ml-52 lg:ml-32 xl:ml-48">
                  <Oval
                    height={80}
                    width={80}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </div>
              ) : (
                <input
                  className="mb-6 bg-[#2B5EAD] text-white font-semibold md:py-[18px] py-[14px] w-full rounded-lg cursor-pointer"
                  type="submit"
                  name="contact"
                  value="Contact Now"
                ></input>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="">
              <h1 className="text-white text-2xl">
                &#9989; Thank you! Your Message has been recorded!
              </h1>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default ContactForm;
