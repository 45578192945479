import React, { useEffect, useState } from 'react'
import bgImage from "../assets/images/bannerTop.jpg"
import { useLocation } from 'react-router-dom';

const BannerTop = () => {
    let location = useLocation();

    const getPageName = () => {
        let pageName = ""
        switch (location.pathname) {
            case "/about":
                pageName = "About"
                break;
            case "/contact":
                pageName = "Contact Us"
                break;
            case "/supply-and-distribution":
                pageName = "Supply and Distribution"
                break;
            default:
                break;
        }
        return pageName
    }

    return (
        <>
            {
                location.pathname === "/" ? "" :
                    <div style={{ backgroundImage: `url(${bgImage})` }}
                        className="w-full h-[112px] bg-no-repeat bg-top bg-cover">
                        <h1 className="text-white text-center lg:text-[20px] font-sans text-xl p-10 font-semibold uppercase">{getPageName()}</h1>
                    </div>
            }
        </>
    )
}

export default BannerTop