import React from "react";
import image_15 from "../assets/images/img15.jpg";
// import ImQuotesLeft from "react-icons/bs";

const Section7 = () => {
  return (
    <>
      <div className="grid lg:grid-cols-2 lg:space-x-10">
        <img className="rounded-2xl" src={image_15} alt="" />
        <div className="md:mt-20 xs:mr-8">
          <div>
            <p className=" xl:mt-16 mt-2 sm:my-4 xs:mt-6 ">
              <span className="font-bold sm:mx-4  "> WHO WE ARE </span>
            </p>
            <h1 className="xl:mt-4 font-semibold sm:text-3xl  sm:mx-4 xs:mt-2">
              What We Offer For You
            </h1>
            <p className="sm:text-lg text-sm sm:font-normal sm:mx-4 xs:mt-2">
              Allama Iqbal Hospital provides a wide range of services. We
              diagnose and treat patients suffering from various issues and
              diseases. You are one click away from getting best treatment
            </p>
          </div>
          {/* <div
            className="rounded-2xl mt-3 sm:m-4 w-full "
            style={{ background: "#D9D9D9" }}
          >
            <div className="justify-content-center p-10">
              <p className="text-lg font-semibold text-center">
                I didn’t think this would be a pleasant experience,but she put
                me at ease. I told her I’m not a big fan of the dentist, and
                she totally understood. The thing I liked best was that she
                immediately relieved my pain. She was really understanding and
                quick to respond.
              </p>
              <div className="grid grid-cols-4 sm:ml-4">
                <div className="col-spans-1">
                  <img
                    className="w-20 rounded-full mt-4 "
                    src="https://tse1.mm.bing.net/th?id=OIP.iIqISqABXL3OBU1V37j8DQHaHa&pid=Api&P=0"
                    alt=""
                  />
                </div>
                <div className="mt-6 col-spans-3 lg:ml-5 xl:ml-0">
                  <h4 className="font-bold ">Jasika Morn</h4>
                  <h4>Skincare Patient</h4>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Section7;
