import React, { useEffect, useState } from "react";
import Section6Card from "../utils/Section6Card";

// images
import Psimg from "../assets/images/Ps.jpg";
import eye from "../assets/images/eye.jpg";
import bp from "../assets/images/bp.jpg";
import dentist from "../assets/images/dentist.jpg";
import fire from "../assets/images/fire.jpg";
import opd from "../assets/images/opd.jpg";

const Section6 = () => {
  const [secSixTabId, setSecSixTabId] = useState(0);

  const handleSecSixTab = (id) => {
    setSecSixTabId(id);
  };

  const handleContent = (id) => {
    let obj = {
      head: "",
      para: "",
      list: [],
      image: null,
    };
    switch (id) {
      case 0:
        obj.head = "COVID-19";
        obj.para =
          "Signs and symptoms of coronavirus disease may appear two to 14 days after exposure. This tme after explore and before having symptoms is called the incubation period. Common signs and symptoms can include.";
        obj.list = ["Fever", "Cough", "Tiredness", "Loss of taste and smell"];
        obj.image = Psimg;
        return obj;
      case 1:
        obj.head = "Gynaecology";
        obj.para =
          "We are providing collaborative model of care for femals with physicians and surgeons to serve all of our patients in a safe, respectful,  evidence based and women centred services.";
        obj.list = [
          "Pregnancy and Child birth ",
          "Infertility ",
          "Diagnostic Endoscopy ",
          "Gynaecological Surgeries ",
        ];
        obj.image = fire;
        return obj;

      case 2:
        obj.head = "DERMATOLOGY";
        obj.para =
          "Provides consultation and the latest treatments for all skin disorders for all ages including treatment of sexually transmitted diseases.This is an era of cosmetic procedures to make the skin beautiful, youthful and glamorous for both men and women. Most of the procedures do not include extensive plastic surgeries. They can be done either injections, lasers and chemicals. ";
        obj.list = [
          "Rejuvenation",
          "Laser air removal",
          "Chemical Peeling",
          "Resurfacing with lasers",
        ];
        obj.image = eye;
        return obj;
      case 3:
        obj.head = "Dentistry";
        obj.para =
          "The team of professionals at the Dental Clinic are committed to extend the highest quality, effective and sustainable oral healthcare throughout employment of contemporary gadgetry , an assortment of advanced treatment modalities and reliance on single use of clinical polroducts";
        obj.list = [
          "Oral Surgeries",
          "Aesthetic Dentistry ",
          "Orthodontics ",
          "Facility of dental procedures under General Anesthesia ",
        ];
        obj.image = dentist;
        return obj;
      case 4:
        obj.head = "Pharmacy";
        obj.para =
          "Located on the ground floor, the Allama Iqbal hospital ensures the availability of medicines at the premises round the clock  making sure that you get the recommended medicines in time with convenience.  In addition to compounding we supply quality medicines of renowned brands to OPD and IPD patients including all sort of surgical and disposable items.";
        obj.list = [
          "Drugs",
          "Medical Disposables",
          "Infusions",
          "Supply and Distribution setup",
        ];
        obj.image = opd;
        return obj;
    }
  };

  useEffect(() => {}, [secSixTabId]);

  return (
    <>
      <div id="section7" className="bg-[#D9EFF9] w-full pb-20">
        <div className="container mx-auto lg:px-20">
          <div className="py-10">
            <h1 className="lg:text-[18px] text-center lg:font-semibold text-base uppercase">
              Popular Services
            </h1>
            <h2 className="pt-6 text-center lg:text-3xl font-semibold text-sm">
              Give us a chance to prove that we care
            </h2>
          </div>
          <div className="grid lg:grid-cols-5 container mx-auto lg:px-12 xl:px-0 hidden lg:grid">
            <button
              className={`sm:ml-0 h-[4.188rem] hover:bg-[#2B5EAD] hover:text-white ${
                secSixTabId === 0 ? "bg-[#2B5EAD] text-white" : ""
              }`}
              onClick={() => handleSecSixTab(0)}
            >
              Covid-19
            </button>
            <button
              className={`sm:text-cente h-[4.188rem] hover:bg-[#2B5EAD] hover:text-white ${
                secSixTabId === 1 ? "bg-[#2B5EAD] text-white" : ""
              }`}
              onClick={() => handleSecSixTab(1)}
            >
              Gynaecology
            </button>
            <button
              type="button"
              className={`h-[4.188rem] hover:bg-[#2B5EAD] hover:text-white ${
                secSixTabId === 2 ? "bg-[#2B5EAD] text-white" : ""
              }`}
              onClick={() => handleSecSixTab(2)}
            >
              Dermatology
            </button>
            <button
              type="button"
              className={`h-[4.188rem] hover:bg-[#2B5EAD] hover:text-white ${
                secSixTabId === 3 ? " bg-[#2B5EAD] text-white" : ""
              }`}
              onClick={() => handleSecSixTab(3)}
            >
              Dentistry
            </button>
            <button
              type="button"
              className={`h-[4.188rem] hover:bg-[#2B5EAD] hover:text-white ${
                secSixTabId === 4 ? " bg-[#2B5EAD] text-white" : ""
              }`}
              onClick={() => handleSecSixTab(4)}
            >
              Pharmacy
            </button>
          </div>

          <Section6Card
            handleContent={handleContent}
            secSixTabId={secSixTabId}
          />
        </div>
      </div>
    </>
  );
};

export default Section6;
