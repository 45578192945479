import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Reception from "../assets/images/about.jpg";
import props from "react";
import img from "../assets/images/image 25.jpg";

const About = () => {
  const [question, setquestion] = useState(0);
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);
  return (
    <>
      <div className="container mx-auto lg:px-20 lg:grid lg:grid-cols-5 lg:gap-4 lg:mt-24">
        <img
          className="hidden lg:block col-span-2 xl:w-[515px] w-screen xl:h-[487px] h-screen justify-between"
          src={Reception}
          alt="img"
        />
        <div className="col-span-3">
          <h1 className="mt-8 lg:mt-32 lg:pt-20 xl:text-[36px] md:text-3xl lg:text-2xl items-start p-2 font-bold text-gray-800">
            Welcome to Allama Iqbal Hospital
          </h1>
          <p className="p-4 text-slate-600 text-[14px]">
            Allama Iqbal Hospital is one of the best healthcare service provider
            in Haripur. Providing you with the best patient experience through
            quality treatment and diagnostic services. At Allama Iqbal Hospital
            the treatments are carried out using latest healthcare technology.
            Allama Iqbal Hospital is known to be providing top quality treatment
            in Haripur.
          </p>
        </div>
      </div>
      <div className="container mx-auto lg:grid lg:grid-cols-2 gap-4 lg:px-20 lg:py-20 px-10 py-10  ">
        <div></div>

        <div><img src={img} alt="" /></div>

      </div>
    </>
  );
};

export default About;
