import React from 'react'
import { Section4Data } from "../utils/json/Section4Data";
import Section4Card from '../utils/Section4Card';

const Section4 = () => {
    return (
        <>
            <div className="grid lg:grid-cols-3 sm:mt-4 my-[40px] gap-8">
                {Section4Data.map((data) => (
                    <Section4Card data={data} />
                ))}
            </div>
        </>
    );
};

export default Section4




