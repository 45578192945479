import React from "react";
import IMG from "../assets/images/cardiologist.jpg";
import image_11 from "../assets/images/image 11.jpg";

const Section3 = () => {
  return (
    <>
      <div className="w-full mb-[450px] md:mb-[250px] lg:mb-24 xl:mb-44">
        <div
          className="relative h-[80vh] w-full z-[10]"
          style={{
            background: `url(${IMG}) no-repeat center center / cover`,
          }}
        >
          <div className="container mx-auto md:px-20">
            <div>
              <h1 class="absolute text-white top-10 lg:top-20 lg:left-20 left-8 text-[18px]">
                MEET OUR TEAM
              </h1>
              <h1 class="absolute text-white top-16 lg:top-[110px] lg:left-20 left-8 text-[24px] lg:text-[36px] font-[600]">
                Specialist Doctors
              </h1>
              <div className="absolute top-28 lg:top-[180px] mb-[252px]">
                <p class=" text-white  xl:text-[18px] text-[14px] lg:font-[400]">
                  We have specialized doctors that are highly <br /> qualified and trained professionals
                </p>
                <p class="text-white lg:text-[18px] lg:font-[400] text-[14px]">
                </p>
              </div>
            </div>

            <div className="pt-80">
              <div className="z-[10000] w-full shadow-2xl bg-white rounded-2xl">
                <div className="grid md:grid-cols-2">
                  <div className="lg:ml-14 xs:mx-4">
                    <img
                      className="w-full"
                      src={image_11}
                      alt=""
                    />
                  </div>
                  <div className="lg:mt-20 mt-10 px-8">
                    <h1 className="font-bold text-lg">
                      Dr. Maria Rehman
                    </h1>
                    <p className="my-1 text-lg">
                      Gynecologist
                    </p>
                    <hr className="my-2 md:my-4 bg-black" />
                    <p className="text-md pr-12 mb-8">
                      We deal with a wide range of issues like pregnancy, childbirth,
                      menstruation, fertility issues, hormone disorders, Pelvic
                      inflammatory disease, breast cancer, ovarian cancer,
                      infertility, PCOS, and other vaginal diseases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section3;
