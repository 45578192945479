import React from "react";
import Rectangle from "../assets/images/rectangle.jpg";

const SAndD = () => {
  return (
    <>
      <div className="container mx-auto py-10 lg:px-20">
        <div className="lg:grid lg:grid-cols-2 mb-[48px] lg:gap-6">
          <div className="mr-5">
            <h1 className="title-font mt-5 lg:mt-10 lg:text-4xl text-2xl mb-6 font-medium text-gray-900">
              Supply & Distribution
            </h1>
            <p className="mb-6 leading-relaxed lg:text-lg text-sm">
              Hospital Supply And distribution possesses one of the largest distribution networks in the KPK province
            </p>
            <p className="mb-6 leading-relaxed lg:text-lg text-sm">
              Hospital distribution is in alliance with 100 Dealers and over 155 mini dealers. We have served over 26,000 General Customers and Chemists and 380 Hospitals. Distribution has history  of customer care attaining customer satisfaction
            </p>
            <p className="mb-6 leading-relaxed lg:text-lg text-sm">
              Hospital actively conduct CMEs programmes to share new medical methodologies and to create value in healthcare sector. Hospital distribution has also been instrumental in facilitating the spread of medical knowledge for the benefit of medical community & general public through arrangement of lectures, seminar and scientific training workshop
            </p>
            <p className="mb-12 leading-relaxed lg:text-lg text-sm">
              Allama Iqbal uses top of the line facilities for it's warehouse resulting in fully functional systems ensuring products are always handed. not only in the correct,  but also most efficient and cost effective manner. Our warehouse entails a 24 hour temperature monitoring system and a controlled temperature area to ensure safe maintenance of drugs.
              The warehouse also has a secure logging system along with emergency care mechanisms, to cater for unforeseen events. From secure storage of products to ambient snd temperature- controlled sites , our warehouse is equipped with highly skilled management and operations teams to successfully handle warehousing operations.
            </p>
            <p className="mt-6 lg:text-[1.2rem] ">
              Chief Executive Officer <br />
              <strong>Dr. Muhammad Akram Khan</strong>
            </p>
          </div>
          <div className="w-full mt-5 lg:mt-10">
            <img className="rounded-2xl w-full" alt="hero" src={Rectangle} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SAndD;