import { React } from "react";

const SectionGrid4Card = ({ data }) => {
    return (<div className="border rounded-t-3xl shadow-xl" key={data.id}>
        <div>
            <img
                className="rounded-tl-lg w-full"
                src={data.image}
                alt=""
            />
            <h1 className="text-[20px] font-[700] px-8 mt-10 mb-2">
                {data.name}
            </h1>
            <p className="px-8 text-lg font-[400px]">
                {data.designation}
            </p>
            <p className="ml-6">
                __________________
            </p>
            <p className="mx-6 mt-[1.813rem] text-md mb-[3.188rem]">
                {data.details}
            </p>
        </div>
    </div>)
};

export default SectionGrid4Card;