import React from "react";
import bgImage from "../assets/images/bannerTop.jpg";
import logo from "../assets/images/logofooter.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className="w-full bg-no-repeat bg-top bg-cover"
    >
      <div className="xs:container mx-auto md:px-6 lg:px-20">
        <div className="grid md:grid-cols-6 md:gap-4 text-center pt-10 pb-6 text-white">
          <div className="md:col-span-2 items-start text-left pb-8 ">
            {/* <img src={Logo} alt="Snapp Logo" className="w-28 mb-1" /> */}
            <img src={logo} alt="" className="w-[60px] h-[60px]" />
            <p className="mt-8 mb-2 mr-2 text-md sm:mb-3 text-justify lg:w-2/3">
              24/7 Access to quality health care <br />
              Call us or fill the form for urgent access.
            </p>
            {/* <div className="mt-5" style={{ color: "#fff" }}>
              <FontAwesomeIcon
                icon={faSquareFacebook}
                className="mr-5 text-2xl"
              />
              <FontAwesomeIcon
                icon={faSquareTwitter}
                className="mr-5 text-2xl"
              />
              <FontAwesomeIcon
                icon={faSquareWhatsapp}
                className="mr-5 text-2xl"
              />
            </div> */}
          </div>
          <div className="md:col-span-4">
            <div className="grid md:grid-cols-4">
              <div className="justify-center text-left md:hidden lg:block"></div>
              <div className="justify-center text-left md:hidden lg:block"></div>
              <div className="justify-center text-left md:col-span-2 lg:col-span-1">
                {/* <h3 className="font-bold sm-1 md:text-xl">
                  Terms & Conditions
                </h3>
                <ul>
                  <li className="my-3 lg:text-md text-sm">Privacy Policy</li>
                  <li className="my-3 lg:text-md text-sm">Support a Product</li>
                  <li className="my-3 lg:text-md text-sm">Term of use</li>
                </ul> */}
              </div>
              <div className="justify-center text-left md:col-span-2 lg:col-span-1">
                <ul>
                  <h3 className="font-bold lg:text-xl">Connect with us</h3>
                  <li className="my-7 text-sm">
                    <div className="grid grid-cols-7 gap-4">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="mr-3 text-lg lg:text-xl"
                      />
                      <p className="text-sm">
                        info@allamaiqbalhospital.com
                      </p>
                    </div>
                  </li>
                  <li className="my-7 text-sm">
                    <div className="grid grid-cols-7 gap-4">
                      <FontAwesomeIcon
                        icon={faPhone}
                        className="mr-3 text-lg lg:text-xl"
                      />
                      <p className="text-sm col-span-6">+92 995 627555</p>
                    </div>
                  </li>
                  <li className="my-7 text-sm">
                    <div className="grid grid-cols-7">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        className="mr-3 text-lg lg:text-xl col-span-1"
                      />
                      <p className="text-left col-span-6 text-sm">
                        Shakar Shah Rd, Mohallah Ferozpura, Haripur, Khyber-
                        Pakhtunkhwa
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="text-center justify-center md:font-bold mt-10 text-xs md:text-lg mb-6">
                @ Copyright Snapp Finance 2022. All Rights
            </div> */}
      </div>
    </div>
  );
};

export default Footer;
