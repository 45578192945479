import Image1 from "../../assets/images/rectangle1.jpg";
import Image2 from "../../assets/images/rectangle2.jpg";
import Image3 from "../../assets/images/rectangle3.jpg";

export const Section4Data = [
  {
    id: 1,
    image: Image1,
    name: "Dr. Hassan Nasir",
    designation: "PAEDERIATIC SURGEON",
    details:
      "We offer professional neurosurgery services with a commitment to achieving excellent outcomes for our patients and their families",
  },
  {
    id: 2,
    image: Image2,
    name: "Dr.Niaz Akber Afridi",
    designation: "SKIN SPECIALIST",
    details:
      "We provide skin treatments in various settings such as spas, salons, or doctor's offices. Consults with clients to evaluate skin conditions and makes initial recommendations for skin treatments and solutions. ",
  },
  {
    id: 3,
    image: Image3,
    name: "Dr. Adnan Tahir",
    designation: "CARDIAC SURGEON",
    details:
      "Our expert team treats a variety of conditions that affect the advanced education and training in performing surgery on the heart and the major blood vessels around it.",
  },
];

export default { Section4Data };
