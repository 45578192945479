import React from "react";
import { Link } from "react-router-dom";

// Utils
import ShowcaseCard from "../utils/ShowcaseCard.jsx";

// Components
import Section1 from "./Section1.jsx";
import Section2 from "./Section2.jsx";
import Section3 from "./Section3.jsx";
import Section4 from "./Section4.jsx";
import Section5 from "./Section5.jsx";
import Section6 from "./Section6.jsx";
import Section7 from "./Section7.jsx";

// Images
import Showcase from "../assets/images/showcase.jpg";
import TimeImage from "../assets/images/time.png"

// Fonts
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faHeartPulse,
  faBookMedical,
} from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  return (
    <>
      <div
        className="lg:h-[80vh] w-full"
        style={{
          background: `url(${Showcase}) no-repeat center center / cover`,
        }}
      >
        <div className="xs:container mx-auto md:px-6 lg:px-20">
          <div className="text-white">
            <h1 className="md:text-5xl text-2xl md:pt-24 pt-6 lg:leading-tight font-semibold">
              Take the best Quality
              <br /> Treatment
            </h1>
            <p className=" xs:text-sm  md:mt-6 lg:w-2/5 mt-2 ">
              Allama Iqbal Hospital attracts specialists in every medical field
              to help you find the right doctor with a dedication to personally
              guide and treat you. We are for your good health we are known for
              the excellence of person-centered care.
            </p>
            <div className="mt-14 md:mt-8">
              <a href="#appointment">
                <button class="inline-flex items-center bg-[#2B5EAD] border-0 py-2 px-4 xs:my-4 focus:outline-none hover:bg-[#3870c7] text-base mt-4 md:mt-0 rounded-lg">
                  Get Appointment
                </button>
              </a>
              <a href="#section7">
                <button class="inline-flex items-center bg-[#2B5EAD] border-0 py-2 px-4  ml-6 xs:my-4 focus:outline-none hover:bg-[#3870c7] text-base mt-4 md:mt-0 rounded-lg">
                  More Info
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="xs:container mx-auto md:px-6 lg:px-20">
        <div className="mt-[20px] lg:mt-[-60px]">
          <div className="grid lg:grid-cols-3 gap-5 xl:gap-10">
            {[
              {
                icon: (
                  <FontAwesomeIcon icon={faPhone} className="mr-5 text-2xl" />
                ),
                color: "#2B5EAD",
                heading: "Emergency Care",
                content:
                  "Emergency services play a specific part in supporting patients to receive the right care, by the right person, as quickly as possible.",
                button: false,
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faHeartPulse}
                    className="mr-5 text-2xl"
                  />
                ),
                color: "#0092FF",
                heading: "Primary Care",
                content:
                  "We make sure our members have access to the best professional tools, advice, and information. For your primary care, we have GP, pharmacist, dentist, gynecologist, physiologist, optician services, and more.",
                button: true,
              },
              {
                icon: (
                  <FontAwesomeIcon
                    icon={faBookMedical}
                    className="mr-5 text-2xl"
                  />
                ),
                color: "#2B5EAD",
                heading: "Opening Hours",
                // content: (
                //   <table class="table-auto">
                //     <tbody>
                //       <tr style={{ borderBottom: "1px solid #fff" }}>
                //         <td className="py-2">Monday - Friday</td>
                //         <td className="w-2/5 py-2">08:00am - 10:00pm</td>
                //       </tr>
                //       <tr style={{ borderBottom: "1px solid #fff" }}>
                //         <td className="py-2">Saturday</td>
                //         <td className="w-2/5 py-2">08:00am - 10:00pm</td>
                //       </tr>
                //       <tr>
                //         <td className="py-2">Sunday</td>
                //         <td className="w-2/5 py-2">08:00am - 10:00pm</td>
                //       </tr>
                //     </tbody>
                //   </table>
                // ),
                content: "Monday - Sunday",
                image: TimeImage,
                button: false,
              },
            ].map((item) => {
              return (
                <div>
                  <ShowcaseCard
                    icon={item.icon}
                    color={item.color}
                    heading={item.heading}
                    content={item.content}
                    button={item.button}
                    image={item.image ? item.image : null}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <Section1 />
        <Section2 />
      </div>
      <Section3 />
      <div className="xs:container mx-auto md:px-6 lg:px-20 lg:py-28">
        <Section4 />
        <div>
          <Section5 />
        </div>
      </div>
      <Section6 />
      <div className="xs:container mx-auto md:px-6 lg:px-20 lg:py-28 py-4">
        <Section7 />
      </div>
    </>
  );
};

export default Home;
