import React, { useState } from "react";
import img from "../assets/images/telphone.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import props from "react";
import { Link } from "react-router-dom";
import ContactForm from "../utils/ContactForm";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";


const Contact = () => {
  const [question, setquestion] = useState(0);
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  return (
    <div className="container mx-auto px-2 lg:px-20">

      {/* <div className="mt-[5.375rem] bg-[#26779B] h-[46px] w-full rounded-lg  ">
        <button className=" ">
          <p className=" px-5 py-2 text-white"> Administration</p>
          </button>
      </div>
      <div className="mt-2 bg-[#26779B] h-[46px] w-full rounded-lg  ">
        <button className=" ">
          <p className=" px-5 py-2 text-white"> Supply & Distribution</p>
          </button>
      </div>  */}

      <div className="w-full mx-auto mt-12">
        <ul>
          <li className="py-2 border-gray-200 border-solid border-b">
            <div className="flex justify-between items-center bg-[#26779B] rounded-lg">
              <h3 className="p-4 text-gray-800 text-sm font-bold md:text-lg text-white xl:text-[20px] w-10/12">
                Administration
              </h3>
              <div
                className="cursor-pointer"
                onClick={() =>
                  question === 0 ? setquestion(null) : setquestion(0)
                }
              >
                {question === 0 ? (
                  <svg
                    className="rounded-full"
                    style={{ background: "#002D51" }}
                    viewBox="0 0 24 24"
                    fill="white"
                    height="40px"
                    width="40px"
                    {...props}
                  >
                    <path d="M8.7 14.7a1 1 0 01-1.4-1.4l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L12 11.42l-3.3 3.3z" />
                  </svg>
                ) : (
                  <svg
                    className="rounded-full"
                    style={{ background: "#002D51" }}
                    viewBox="0 0 24 24"
                    fill="white"
                    height="40px"
                    width="40px"
                    {...props}
                  >
                    <path d="M15.3 9.3a1 1 0 011.4 1.4l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 011.4-1.4l3.3 3.29 3.3-3.3z" />
                  </svg>
                )}
              </div>
            </div>
            {question === 0 && (
              <p className="p-2 text-gray-800 bg-white border-gray-800 border-2 text-sm xl:text-lg rounded-b-lg font-semibold">
                hr@allamaiqbalhospital.com <br />
                info@allamaiqbalhospital.com
              </p>
            )}
          </li>
          <li className="pb-2 border-gray-200 border-solid border-b">
            <div className="flex justify-between items-center bg-[#26779B] rounded-lg">
              <h3 className="p-4 text-gray-800 text-sm font-bold md:text-lg text-white xl:text-[20px]">
                Supply & Distribution
              </h3>
              <div
                className="cursor-pointer"
                onClick={() =>
                  question === 1 ? setquestion(null) : setquestion(1)
                }
              >
                {question === 1 ? (
                  <svg
                    className="rounded-full"
                    style={{ background: "#002D51" }}
                    viewBox="0 0 24 24"
                    fill="white"
                    height="40px"
                    width="40px"
                    {...props}
                  >
                    <path d="M8.7 14.7a1 1 0 01-1.4-1.4l4-4a1 1 0 011.4 0l4 4a1 1 0 01-1.4 1.4L12 11.42l-3.3 3.3z" />
                  </svg>
                ) : (
                  <svg
                    className="rounded-full"
                    style={{ background: "#002D51" }}
                    viewBox="0 0 24 24"
                    fill="white"
                    height="40px"
                    width="40px"
                    {...props}
                  >
                    <path d="M15.3 9.3a1 1 0 011.4 1.4l-4 4a1 1 0 01-1.4 0l-4-4a1 1 0 011.4-1.4l3.3 3.29 3.3-3.3z" />
                  </svg>
                )}
              </div>
            </div>
            {question === 1 && (
              <p className="p-2 text-gray-800 bg-white border-gray-800 border-2 text-sm xl:text-[18px] rounded-b-lg font-semibold">
                supply@allamaiqbalhospital.com
              </p>
            )}
          </li>

        </ul>
      </div>

      <div className="lg:grid lg:grid-cols-2 mt-[5.375rem]">
        <div>
          <img src={img} alt="" />

        </div>
        <div>
          <ContactForm />
        </div>
      </div>
      <div className="w-full lg:my-16 my-[10px]">
        <iframe
          className="w-full h-[400px]"
          src="https://maps.google.com/maps?q=Allama%20Iqbal%20hospital%20haripur&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          id="gmap_canvas"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
