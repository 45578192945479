import React from "react";
import Img7 from "../assets/images/image 7.jpg";

const Section1 = () => {
  return (
    <>
      <div className="grid xl:grid-cols-2 lg:grid-cols-2 my-24">
        <div className="w-full">
          <img className="sm:px-4 w-full" src={Img7} alt="" />
        </div>
        <div className="lg:mt-56 sm:mt-4 sm:px-2">
          <h1 className="font-bold text-lg mx-4 mt-5 lg:mt-0">Who We Are</h1>
          <h1 className="font-bold text-xl md:text-3xl mx-4">
            Choose The Best Care For Yourself
          </h1>
          <p className="md:m-4 mx-4 mt-3">
            <span className="font-bold">
              We offer reasonable pricing health care plans.
            </span>
            <br />
            We make sure our members have access to the best professional tools,
            advice, and information. For your primary care, we have GP,
            pharmacist, dentist, gynecologist, physiologist, optician services
            and more.
          </p>
        </div>
      </div>
    </>
  );
};

export default Section1;
