import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Section2Card = ({ cardsDate }) => {
  return (
    <>
      <div className="relative">
        <div>
          <h1 className="text-center font-semibold md:text-lg uppercase">Who We Are</h1>
          <h1 className="text-center lg:font-semibold md:mt-6 lg:text-3xl sm:font-light  sm:mx-4 sm:text-xs">
            What We Offer for You
          </h1>
          <p className="text-center md:mx-[154]  md:mt-3 mt-2 xs:mx-4 lg:font-normal lg:text-xl sm:mx-4 sm:font-light sm:text-xs text-gray-500">
            Life Care Hospital provides a wide range of services. We diagnose
            and treat patients suffering from various issues and diseases.You
            are one click away from getting best treatment
            <br />
          </p>
        </div>
        <div className="grid lg:grid-cols-3 sm:mt-12 my-[40px] gap-3 xl:gap-8">
          {cardsDate.map((currElem) => (
            <div
              className="border rounded-t-3xl relative shadow-xl"
              key={currElem.id}
            >
              <div className="w-full">
                <img className="rounded-tl-lg w-full" src={currElem.image} alt="" />
                <h1 className="text-[20px] font-bold px-8 mt-10 mb-2">
                  {currElem.departmentName}
                </h1>
                <div className="absolute rounded-full bg-[#3B62A1] w-[60px] h-[60px] top-32 left-4 md:top-80 lg:top-32 xl:top-44" >
                  <FontAwesomeIcon icon={currElem.icon} className=" text-white ml-[12.8px] mt-3  text-3xl" />
                </div>
                <p className="px-8 text-sm text-md pb-2 mb-16">
                  {currElem.details}
                </p>
              </div>
              <div className="absolute bottom-4 left-8">
                <a href="#section7">
                  <button className="grid grid-rows-1 grid-flow-col font-semibold text-lg">
                    Learn More
                    {/* <HiArrowRight className="mt-2 ml-2" /> */}
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Section2Card;